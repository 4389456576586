import { useState, useEffect, Fragment } from "react";
import '../../components/glassTheme/Common.scss';
import '../../components/glassTheme/Switch.scss';
import './EventsList.scss';
import React from 'react';
import { BehaviorSubject, Subscription } from "rxjs";
import SearchBar from "../../components/search-bar/SearchBar";
import Loader, { SpinnerType } from "../../components/Spinner/Loader";
import { GiKiwiBird, GiCancel } from "react-icons/gi";
import { FaFlagCheckered } from 'react-icons/fa';
import { FaRegTrashCan, FaRegCirclePlay } from "react-icons/fa6";
import { GrEmptyCircle } from "react-icons/gr";
import FilterButton, { FilterButtonType } from "../../components/filter-button/FilterButton";
import { eventViewModel } from "../EventsDashboard/EventsDashboard.ViewModel";
import { eventState } from "../../Models/Common/EventState";
import { RiExternalLinkFill } from "react-icons/ri";
import { loadingState } from "../../Models/Common/LoadingState";
import { gameType } from "../../Models/Common/GameType";
import { eventSeason } from "../../Models/Common/EventSeason";
import { eventType } from "../../Models/Common/EventType";
import { GiRollingDices, GiConsoleController, GiDuck } from "react-icons/gi";
import { MdPhoneIphone } from "react-icons/md";

interface EventsListProps {
    eventsSubject: BehaviorSubject<eventViewModel[]>;
    loadingStateSubject: BehaviorSubject<loadingState>;
    updateEventState: (eventId: string, newState: eventState) => void;
}

enum EventsFilterType {
    AllStates,
    Active,
    Finished,
    Alphabetical,
    LastUpdated,
    Search
}

const EventsList: React.FC<EventsListProps> = ({
        eventsSubject,
        loadingStateSubject,
        updateEventState
    }) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [events, setEvents] = useState<eventViewModel[]>([]);
    const [eventsFilter, setEventsFilter] = useState<EventsFilterType>(EventsFilterType.Active);
    const [searchTerm, setSearchTerm] = useState<string>('');

    useEffect(() => {
            var subs: Subscription[] = [];
            subs.push(eventsSubject.subscribe((events) => setEvents(events ?? [])));
            subs.push(loadingStateSubject.subscribe((state) => setIsLoading(state !== loadingState.hidden)));
            return () => subs.forEach(s => s.unsubscribe());
    }, [eventsSubject, loadingStateSubject]);

    if (isLoading) {
        return <Loader showMessage={true} spinnerType={SpinnerType.FullPage} loadingStateSubject={loadingStateSubject}/>;
    }

    const filteredEvents = events.filter(event => {
        switch (eventsFilter) {
            case EventsFilterType.Active:
                return (event.state === eventState.Active || event.state === eventState.New)
                    && events.sort((a, b) => b.pairingsLastUpdated.localeCompare(a.pairingsLastUpdated));
            case EventsFilterType.Finished:            
                return event.state === eventState.Finished
                    && events.sort((a, b) => b.pairingsLastUpdated.localeCompare(a.pairingsLastUpdated));
            case EventsFilterType.Alphabetical:            
                return events.sort((a, b) => a.eventId.localeCompare(b.eventId));
            case EventsFilterType.LastUpdated:            
            return events.sort((a, b) => b.pairingsLastUpdated.localeCompare(a.pairingsLastUpdated));
            case EventsFilterType.Search:
                if (searchTerm === "") {
                    return true;
                }
                return event.eventId.toLowerCase().includes(searchTerm.toLowerCase())
                    && events.sort((a, b) => b.pairingsLastUpdated.localeCompare(a.pairingsLastUpdated));
            default:
                return true;
        }
    });

    const eventsFilterValueChange = (value: EventsFilterType) => {
        if (eventsFilter === value) {
            setEventsFilter(EventsFilterType.Active);
            return;
        }
        setEventsFilter(value);
        setSearchTerm("");
    }

    const searchTermValueChange = (value: string) => {
        setEventsFilter(EventsFilterType.Search);
        setSearchTerm(value);
    }

    return (
        <div className="eventsControls">
        <Fragment key={filteredEvents.length}>
            <ul className="events">
                {filteredEvents.map(event => (
                    <li className="event" key={event.eventId}>
                        <div className="event-card">
                        <div className="event-data-section-start">
                                <div className="event-data-column">
                                    { event.details?.gameType === gameType.TradingCardGame &&<GiRollingDices className="state-icon"/>}
                                    { event.details?.gameType === gameType.VideoGame && <GiConsoleController className="state-icon"/>}
                                    { event.details?.gameType === gameType.GO && <MdPhoneIphone className="state-icon"/>}
                                    { event.details?.gameType === gameType.Other && <GiDuck className="state-icon"/>}
                                    <label className="glass-caption italic">{gameType.shortName(event.details?.gameType)}</label>
                                </div>
                            <div className="event-data-pair">
                                <label className="event-subtitle">{event.eventId}</label>
                                <label className="event-title">{event.details?.location} {eventType.name(event.details?.type)} {eventSeason.name(event.details?.season)}</label>
                                { event.state === eventState.Active && <label className="glass-caption bold italic">Currently in Round {event.highestPairingsRound}</label> }
                                </div>
                            </div>
                            <div className="event-data-section-middle">
                                <div className="event-data-column">
                                    <div className="event-data-pair">
                                        { event.state === eventState.New &&<GrEmptyCircle className="state-icon"/>}
                                        { event.state === eventState.Active && <FaRegCirclePlay className="state-icon"/>}
                                        { event.state === eventState.Finished && <FaFlagCheckered className="state-icon"/>}
                                        { event.state === eventState.Cancelled && <GiCancel className="state-icon"/>}
                                        { event.state === eventState.Deleted && <FaRegTrashCan className="state-icon"/>}
                                        <label className="state-label">{eventState.name(event.state)}</label>
                                    </div>
                                    { event.state === eventState.New || event.state === eventState.Active || event.state === eventState.Finished ?
                                        <button className="glass-button-mini" onClick={() => updateEventState(event.eventId, eventState.Cancelled)}>Cancel</button> : null }
                                    { event.state === eventState.Cancelled ?
                                        <button className="glass-button-mini" onClick={() => updateEventState(event.eventId, eventState.Deleted)}>Delete</button> : null }
                                </div>
                                <div className="event-data-column-left">
                                    <div className="event-data-pair">
                                        <label className="event-detail-header"><i>Pairings Updated</i></label>
                                        <label className="event-detail">{event.pairingsReadableLastUpdated != "" ? event.pairingsReadableLastUpdated : "Never"}</label>
                                    </div>
                                    <div className="event-data-pair">
                                        <label className="event-detail-header"><i>Standings Updated</i></label>
                                        <label className="event-detail">{event.standingsReadableLastUpdated != "" ? event.standingsReadableLastUpdated : "Never"}</label>
                                    </div>
                                    <div className="event-data-pair">
                                        <label className="event-detail-header"><i>Update Count:</i></label>
                                        <label className="event-detail">{event.pairingsUpdateCount} Pairings, {event.standingsUpdateCount} Standings</label>
                                    </div>
                                </div>
                            </div>
                            <div className="event-data-section-end">
                                <div className="nav-button-container">
                                        <a className="nav-button" href={"/events/"+event.eventId+"/pairings"} target="">Pairings <RiExternalLinkFill className="glass-button-icon-right"/></a>
                                        <a className="nav-button" href={"/events/"+event.eventId} target="">Scorekeeper <RiExternalLinkFill className="glass-button-icon-right"/></a>
                                        <a className="nav-button" href={"/events/"+event.eventId+"/stream"} target="">Stream <RiExternalLinkFill className="glass-button-icon-right"/></a>
                                </div>
                            </div>
                        </div>
                    </li>
                ))}
                {filteredEvents.length == 0 && <div className="searchError">
                    <label className="glass-h3 italic">No events found for current filters.</label>
                    <GiKiwiBird className="searchErrorIcon"/>
                        </div>}
            </ul>
        </Fragment>
        <div className="filterControls">
            <FilterButton
                isToggled={eventsFilter === EventsFilterType.AllStates}
                onToggle={() => eventsFilterValueChange(EventsFilterType.AllStates)}
                iconType={FilterButtonType.AllStates}/>
            <FilterButton
                isToggled={eventsFilter === EventsFilterType.Active}
                onToggle={() => eventsFilterValueChange(EventsFilterType.Active)}
                iconType={FilterButtonType.Active}/>
            <FilterButton
                isToggled={eventsFilter === EventsFilterType.Finished}
                onToggle={() => eventsFilterValueChange(EventsFilterType.Finished)}
                iconType={FilterButtonType.Finished}/>
            <FilterButton
                isToggled={eventsFilter === EventsFilterType.Alphabetical}
                onToggle={() => eventsFilterValueChange(EventsFilterType.Alphabetical)}
                iconType={FilterButtonType.Alphabetical}/>
            <FilterButton
                isToggled={eventsFilter === EventsFilterType.LastUpdated}
                onToggle={() => eventsFilterValueChange(EventsFilterType.LastUpdated)}
                iconType={FilterButtonType.LastUpdated}/>
            <SearchBar
                searchTerm={searchTerm}
                onSearch={searchTermValueChange}/>
        </div>
    </div>
    );
}

export default EventsList;